<template>
  <div>
    <el-row class="dp-flex row">
      <!-- <el-col :span="18" style="text-align:right"> -->
      <div class="max-w-90">
        <el-dropdown
          trigger="click"
          @command="handleCommand"
          v-if="myRegionGroup.length"
          :hide-on-click="false"
          class="w-100"
        >
          <div class="dp-flex max-w-100 align-item-c">
            <el-tooltip
              class="item"
              effect="dark"
              :content="name"
              placement="bottom"
            >
              <div class="el-dropdown-link ellipsis-1">{{ name }}</div>
            </el-tooltip>

            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              :class="[ids.includes(item.id) ? 'activeSelect' : '']"
              v-for="item in myRegionGroup"
              :key="item.id"
              :command="item"
              :disabled="item.targetingMap | disGroup(geos)"
            >
              <div class="regionGroupList">
                <span>{{ item.name }}</span>
                <span
                  ><i
                    class="el-icon-close"
                    @click.stop="deleRegion(item.id)"
                    v-if="item.editable"
                  ></i
                ></span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- </el-col> -->
        <!-- <el-col :span="6" style="text-align:center"> -->
      </div>
      <el-button
        type="text"
        @click="saveAlert"
        :disabled="!list || !list.length"
        >存为常用</el-button
      >
      <!-- </el-col> -->
    </el-row>
    <!-- 保存设置 -->
    <el-dialog
      title="保存为快捷选项"
      :visible.sync="saveRegionShow"
      width="600px"
      append-to-body
    >
      <div>
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="80px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="名称："
            prop="name"
          >
            <el-input
              v-model="ruleForm.name"
              maxlength="20"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="saveRegionShow = false">取 消</el-button>
        <el-button
          type="primary"
          @click="saveBtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { savedContents, saveSavedContent, savedContentsDel } from '@/api/creatAd.js';
let that;
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    savedType: {
      type: String,
      default: 'GEO_REGION',
    },
    geos: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'Object',
    },
    account: {
      type: String,
      default: '',
    },
    targetUserId: {
      type: String,
      default: '',
    },
  },
  filters: {
    disGroup(v, geos) {
      let disAble = false;
      // console.log(this);
      v.map((item) => {
        if (that.geos.includes(item.country_code)) {
          disAble = true;
        }
        // item.country_code
      });
      return disAble;
    },
  },
  data() {
    return {
      ruleForm: {},
      myRegionGroup: [],
      saveRegionShow: false,
      // savedType:'GEO_REGION',
      // name:'常用',
      ids: [],
    };
  },
  computed: {
    name() {
      if (this.list.length == 0) {
        this.ids.length = 0;
      } else {
        this.ids.length;
      }
      return this.ids.length
        ? this.myRegionGroup
            .filter((v) => this.ids.includes(v.id))
            .map((k) => k.name)
            .join(',')
        : '常用';
    },
    accountId() {
      return this.account || this.$store.getters.accountId;
    },
  },
  methods: {
    getList() {
      savedContents({ accountId: this.accountId, savedType: this.savedType }, this.targetUserId).then((res) => {
        res.data.map((v, index) => {
          if (!v.id) {
            v.id = v.name + index;
          }
        });
        this.myRegionGroup = res.data;
      });
    },
    saveAlert() {
      if (!this.list.length) {
        this.$message({
          type: 'warning',
          message: '请选选择要保存的省份',
        });
        return false;
      }
      this.saveRegionShow = true;
    },
    // 选择省份组
    handleCommand(v) {
      if (this.ids.includes(v.id)) {
        let index = this.ids.indexOf(v.id);
        this.ids.splice(index, 1);
      } else {
        this.ids.push(v.id);
      }
      let selects = (
        this.ids.length ? this.myRegionGroup.filter((m) => this.ids.includes(m.id)).map((k) => k.targetingMap) : []
      ).flat();
      this.$emit('set-regions', JSON.stringify(selects));
    },
    saveBtn() {
      this.ruleForm.content =
        this.type == 'key' ? JSON.stringify(this.list) : JSON.stringify(this.list.map((v) => v.key));
      this.ruleForm.accountId = this.accountId;
      this.ruleForm.type = this.savedType;
      if (!this.ruleForm.name) {
        this.$message({
          type: 'warning',
          message: '请输入省份组名称',
        });
      } else {
        saveSavedContent(this.ruleForm, this.targetUserId).then((res) => {
          if (res.code == 0) {
            this.$message({
              type: 'success',
              message: '保存成功',
            });
            this.saveRegionShow = false;
            this.getList();
          }
        });
      }
    },
    deleRegion(id) {
      let formData = new FormData();
      formData.append('id', id);
      formData.append('savedType', this.savedType);
      savedContentsDel(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '删除成功',
          });
          this.getList();
        }
      });
    },
  },
  created() {
    that = this;
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  flex-flow: row nowrap;
}
.max-w-100 {
  max-width: 100%;
}
.max-w-90 {
  max-width: 90%;
}
.el-dropdown {
  margin: 0 10px;
  .el-dropdown-link {
  }
}
.el-dropdown-menu__item:focus.activeSelect,
.el-dropdown-menu__item.activeSelect {
  background: #ecf5ff;
  color: #66b1ff;
}
.el-dropdown-menu__item.active,
.el-dropdown-menu__item:focus {
  background: transparent;
  color: #606266;
}
.regionGroupList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
}
</style>

<template>
  <div>
    <el-dialog
      title="投放时间段设置"
      :visible="setTimePickerShow"
      width="600px"
      @close="close"
      :append-to-body="true"
      :modal-append-to-body="false"
    >
      <div>
        <vue-drag-time-picker :timesList="timeValue" @setMinute="getValue" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="small">取 消</el-button>
        <el-button type="primary" @click="setTimePicker" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 接收分钟数组，抛出分钟数组
import vueDragTimePicker from "./vueDragTimePicker.vue";
export default {
  props: {
    setTimePickerShow: {
      type: Boolean,
      defalut: false,
    },
    value: {
      type: Array,
      defalut: () => {},
    },
  },
  components: { vueDragTimePicker },
  data() {
    return {
      weeksList: [
        { id: 0, name: "周一" },
        { id: 1, name: "周二" },
        { id: 2, name: "周三" },
        { id: 3, name: "周四" },
        { id: 4, name: "周五" },
        { id: 5, name: "周六" },
        { id: 6, name: "周日" },
      ],
    };
  },
  computed: {
    timeValue() {
      return this.initValue();
    },
  },
  methods: {
    initValue() {
      let strs = "";
      this.weeksList.map((v) => {
        let str = "";
        for (let i = 0; i < 24; i++) {
          str += "0";
        }
        if (this.value && this.value.length) {
          this.value.map((k) => {
            if (k.days.includes(v.id)) {
              let index1 =
                parseInt(k.from.split(":")[0]) +
                (k.from.split(":")[1] == "30" ? 1 : 0);
              let index2 =
                parseInt(k.to.split(":")[0]) +
                (k.to.split(":")[1] == "30" ? 1 : 0);
              let str1 = "";
              for (let i = index1; i < index2; i++) {
                str1 += "1";
              }
              let list = str.split("");
              let str2 = list.splice(
                index1,
                index2 - index1,
                ...str1.split("")
              );
              str = list.join("");
            }
          });
        }

        strs += str;
      });
      return strs;
    },
    close() {
      this.$emit("update:setTimePickerShow", false);
    },
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (JSON.stringify(arr[i]) == JSON.stringify(arr[j])) {
            //第一个等同于第二个，splice方法删除第二个
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },
    // 小时转成分钟
    ChangeStrToMinutes(str){
      var arrminutes = str.split(":");
      if (arrminutes.length == 2) {
        var minutes = parseInt(arrminutes[0]) * 60 + parseInt(arrminutes[1]);
        return minutes;
      }
      else {
        return 0;
      }
    },
    // 获取最终的值
    getValues(selectTimes){
      let times = []
      selectTimes.map(key=>{
        times = this.unique(times.concat(key.times));
      })
      let list = [];
      times.map(v=>{
        let obj = {...v,days:[]};
        selectTimes.map(k=>{
          if(k.times.find(m=>m.from == v.from && m.to == v.to)){
            obj.days.push(k.week);
          }
        })
        list.push(obj)
      })
      list.map(v=>{
        v.start_minute = this.ChangeStrToMinutes(v.from);
        v.end_minute = this.ChangeStrToMinutes(v.to);
        v.days = v.days.map(k=>this.weeksList.find(m=>m.name == k).id)
      })
      return list
      // let times = this.results.
    },
    getValue(k, value) {
      this.initValue(k);
      this.$emit("setTimePicker", this.getValues(value));
    },
    setTimePicker() {
      this.$emit('sure')
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>>

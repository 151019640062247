<template>
  <div>
    <div
      class="editStatus"
      @click.stop="dialogVisible = true"
    >
      <i class="el-icon-edit"></i>
    </div>
    <el-dialog
      title="主页状态"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
      append-to-body
    >
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
      >
        <el-form-item label="主页是否被封：">
          <el-switch
            v-model="form.isBlocked"
            active-color="#F56C6C"
          ></el-switch>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { editPageBlockStatus } from '@/api/creatAd.js';
export default {
  props: {
    page: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        isBlocked: this.page.isBlocked,
      },
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    sure() {
      let params = {
        pageId: this.page.id,
        blocked: this.form.isBlocked,
      };
      editPageBlockStatus(params).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '更新成功',
          });
          this.handleClose();
          this.$emit('getList', true);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.editStatus {
  position: absolute;
  bottom: -10px;
  right: 15px;
}
</style>
